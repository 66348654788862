import Vue from "vue";
import Vuex from "vuex";

import router from "../router"; // eslint-disable-line no-unused-vars
import axios from 'axios' // eslint-disable-line no-unused-vars

const api_root=process.env.VUE_APP_API_ROOT

Vue.use(Vuex);
export const store = new Vuex.Store({
  state: {
    loading: false,
    loadingOverlay: false,
    token: localStorage.getItem("jwt") || null,
    user: JSON.parse(localStorage.getItem("user")) || null,
    layanans: [],
    pasiens: [],
    dataKlaims:[],
    obats:[],
    icd10s:[],
    icd9s:[],
    sep:{},
    cppts:[],
    transaksis:[],
    laborats:[],
    radiologis:[],
    api_url: api_root,
    fileCppt:{},
    fileResep:{},
    fileRadiologi:{},
    penunjanglains:[],
    jenisPenunjangs:[],
    settings: [],
    pesans: [],
    // menus: [],
  },
  getters: {
    loggedIn(state) {
      if (state.token == "null" || state.token == null) {
        return false;
      } else if(state.token=='undefined'){
        localStorage.clear();
        return false;

      }else {
        return true;
      }
    },
    user(state) {
      return state.user;
    },
    layanans(state) {
      return state.layanans;
    },
    pasiens(state) {
      return state.pasiens;
    },
    dataKlaims(state) {
      return state.dataKlaims;
    },
    obats(state){
      return state.obats
    },
    icd10s(state){
      return state.icd10s
    },
    icd9s(state){
      return state.icd9s
    },
    sep(state){
      return state.sep
    },
    cppts(state){
      return state.cppts
    },
    transaksis(state){
      return state.transaksis
    },
    laborats(state){
      return state.laborats
    },
    radiologis(state){
      return state.radiologis
    },
    api_url(state){
      return state.api_url
    },
    token(state){
      return state.token
    },
    fileCppt(state){
      return state.fileCppt
    },
    fileResep(state){
      return state.fileResep
    },
    fileRadiologi(state){
      return state.fileRadiologi
    },
    penunjanglains(state){
      return state.penunjanglains
    },
    settings(state){
      return state.settings
    },
    loading(state){
      return state.loading
    },
    loadingOverlay(state){
      return state.loadingOverlay
    },
    pesans(state){
      return state.pesans
    },
    jenisPenunjangs(state){
      return state.jenisPenunjangs
    },
    // menus(state){
    //   return state.menus
    // }
  },
  mutations: {
    setDataLogin(state, data) {
      state.token = data.token;
      state.user = data.user;
    },
    getAllLayanan(state, datas) {
      state.layanans = datas;
    },
    filterPasien(state, datas) {
      state.pasiens = datas;
    },
    getDataKlaim(state, datas) {
      state.dataKlaims = datas;
    },
    getObat(state, datas) {
      state.obats = datas;
    },
    getICD10(state, datas) {
      state.icd10s = datas;
    },
    getICD9(state, datas) {
      state.icd9s = datas;
    },
    getSep(state, data) {
      state.sep = data;
    },
    getCppt(state, datas) {
      state.cppts= datas;
    },
    getTransaksi(state, datas) {
      state.transaksis= datas;
    },
    getLaborat(state, datas) {
      state.laborats= datas;
    },
    getRadiologi(state, datas) {
      state.radiologis= datas;
    },
    getFileCppt(state, data){
      state.fileCppt= data
    },
    getFileResep(state, data){
      state.fileResep= data
    },
    getFileRadiologi(state, data){
      state.fileRadiologi= data
    },
    getPenunjanglains(state, datas){
      state.penunjanglains= datas
    },
    getJenisPenunjangs(state, datas){
      state.jenisPenunjangs= datas
    },
    getSettings(state, datas){
      state.settings= datas
    },
    getPesans(state, datas){
      state.pesans= datas
    },
    addPesan(state, data){
      // state
      // data
      let pesans= state.pesans
      let ada=false
      pesans.forEach(element => {
        if (element.id==data.id) {
          ada=true
        }
      });
      if (ada==false) {
        state.pesans.push({
          created_at: data.created_at,
          readed_at: data.readed_at,
          foto_profil: data.foto_profil,
          fs_kd_peg: data.fs_kd_peg ,
          id: data.id ,
          karyawan: data.karyawan ,
          kd_register: data.kd_register ,
          nama_lengkap: data.nama_lengkap ,
          pesan: data.pesan ,
          showPesanBaru: data.showPesanBaru ,
        })
      }
    },
    simpanProfil(state, data){
      let user_data= state.user;
      user_data.foto_profil= data.foto_profil
      user_data.foto_profil_full= data.foto_profil_full
      localStorage.setItem('user',JSON.stringify(user_data))

      state.user.foto_profil= data.foto_profil
      state.user.foto_profil_full= data.foto_profil_full
    },
    // getMenu(state, datas) {
    //   state.menus= datas;
    // },
  },
  actions: {
    cekResponseStatus(context, kode){
      if (kode==401) {
        let message= 'Session berakhir! '
        context.dispatch('logout', message)
      } 
      // else {
      //   return new Promise((resolve, reject) => {
      //     resolve('Success')
      //   })
      // }
    },
    login({
      commit // eslint-disable-line no-unused-vars
    }, form) {
      let formPost = new FormData();
      formPost.append('username', form.username)
      formPost.append('password', form.password)
      formPost.append('kode', form.kode)

      axios.post(api_root+"/Auth/login", formPost)
      .then(response => {
        if (response.data.error) {
          alert(response.data.message);
        }else{
          localStorage.setItem('user',JSON.stringify(response.data.user))
          localStorage.setItem('jwt',response.data.token)
          if (localStorage.getItem('jwt') != null){
            let dtMt= {
              token: response.data.token,
              user: response.data.user,
            }
            commit("setDataLogin", dtMt);
            router.push("/")

            // if(this.$route.params.nextUrl != null){
            //     this.$router.push(this.$route.params.nextUrl)
            //     router.push("/home_user")
            // }
            // else {
            //   this.$router.push('admin')
            //     if(is_admin== 1){
            //         this.$router.push('admin')
            //     }
            //     else {
            //         this.$router.push('dashboard')
            //     }
            // }
          }
        }
      })
      .catch(error => {
        alert(error);
      });
    },
    logout({
      commit // eslint-disable-line no-unused-vars
    },message='') {
      localStorage.clear();
      alert(message+"Anda keluar dari sistem")
      window.location.reload()
      // setInterval(function(){ 
      // }, 5000);
    },
    getAllLayanan(context) {
      context.state.loadingOverlay = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.get(api_root+"/Api/Layanan", { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)

        context.commit("getAllLayanan", response.data.data);
        context.state.loadingOverlay = false;
      });
    },
    filterPasien(context, form) {
      context.state.loading = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      let formPost = new FormData();
      formPost.append('instalasi', form.instalasi)
      formPost.append('layanan', form.layanan)
      formPost.append('tanggal', form.tanggal)

      axios.post(api_root+"/Api/Layanan/filterPasien", formPost, { headers })
      .then(response => {
          context.dispatch('cekResponseStatus', response.data.status)

          // console.log(response.data.data);
          context.state.loading = false;
          context.commit("filterPasien", response.data.data);
        });
    },
    getDataKlaim(context) {
      context.state.loadingOverlay = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.get(api_root+"/Api/Layanan/dataKlaim", { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)
        context.commit("getDataKlaim", response.data.data);
        context.state.loadingOverlay = false;
      });
    },
    getObat(context, form) {
      context.state.loadingOverlay = true;

      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Api/Pemeriksaan/resep", form, { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)

        context.commit("getObat", response.data.data);
        context.state.loadingOverlay = false;
      });
    },
    getICD10(context, form) {
      context.state.loadingOverlay = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Api/Pemeriksaan/icd10", form, { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)

        context.commit("getICD10", response.data.data);
        context.state.loadingOverlay = false;
      });
    },
    getICD9(context, form) {
      context.state.loadingOverlay = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Api/Pemeriksaan/icd9", form, { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)

        context.commit("getICD9", response.data.data);
        context.state.loadingOverlay = false;
      });
    },
    getSep(context, form) {
      context.state.loadingOverlay = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Api/Pemeriksaan/sep", form, { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)

        context.commit("getSep", response.data.data);
        context.state.loadingOverlay = false;
      });
    },
    getCppt(context, form) {
      context.state.loadingOverlay = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Api/Pemeriksaan/cppt", form, { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)

        context.commit("getCppt", response.data.data);
        context.state.loadingOverlay = false;
      });
    },
    getTransaksi(context, form) {
      context.state.loadingOverlay = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Api/Pemeriksaan/transaksi", form, { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)

        context.commit("getTransaksi", response.data.data);
        context.state.loadingOverlay = false;
      });
    },
    getLaborat(context, form) {
      context.state.loadingOverlay = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Api/Pemeriksaan/laborat", form, { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)

        context.commit("getLaborat", response.data.data);
        context.state.loadingOverlay = false;
      });
    },
    getRadiologi(context, form) {
      context.state.loadingOverlay = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Api/Pemeriksaan/radiologi", form, { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)

        context.commit("getRadiologi", response.data.data);
        context.state.loadingOverlay = false;
      });
    },
    getFileCppt(context, form) {
      context.state.loadingOverlay = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Api/File/get", form, { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)
        if (response.data.data != null) {
          context.commit("getFileCppt", response.data.data);
        } else{
          context.commit("getFileCppt", {});
        }
        context.state.loadingOverlay = false;

      });
    },
    getFileResep(context, form) { //resep, radiologi
      context.state.loadingOverlay = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Api/File/get", form, { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)
        if (response.data.data != null) {
          context.commit("getFileResep", response.data.data);
        } else{
          context.commit("getFileResep", {});
        }
        context.state.loadingOverlay = false;

      });
    },
    getFileRadiologi(context, form) { //resep, radiologi
      context.state.loadingOverlay = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Api/File/get", form, { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)
        if (response.data.data != null) {
          context.commit("getFileRadiologi", response.data.data);
        } else{
          context.commit("getFileRadiologi", {});
        }
        context.state.loadingOverlay = false;

      });
    },
    getPenunjanglains(context, form) {
      context.state.loadingOverlay = true;

      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Api/File/getPenunjang", form, { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)

        if (response.data.data != null) {
          context.commit("getPenunjanglains", response.data.data);
        } else{
          context.commit("getPenunjanglains", []);
        }
        context.state.loadingOverlay = false;
      });
    },
    getJenisPenunjangs(context) {
      context.state.loadingOverlay = true;

      let headers = { 
        "Authorization": context.state.token,
      };
      axios.get(api_root+"/Api/Pemeriksaan/jenisPenunjang", { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)

        context.commit("getJenisPenunjangs", response.data.data);
        context.state.loadingOverlay = false;
      });
    },
    storeClaim(context, form) {
      return new Promise((resolve, reject) => {
        let headers = { 
          "Authorization": context.state.token,
        };
        axios.post(api_root+"/Api/Pemeriksaan/storeKlaim", form, { headers })
        .then(response => {
          context.dispatch('cekResponseStatus', response.data.status)
          resolve(response.data);
        }, error => {
          reject(error);
        })
      })
    },
    voidClaim(context, form) {
      return new Promise((resolve, reject) => {
        let headers = { 
          "Authorization": context.state.token,
        };
        axios.post(api_root+"/Api/Pemeriksaan/voidKlaim", form, { headers })
        .then(response => {
          context.dispatch('cekResponseStatus', response.data.status)
          resolve(response.data);
        }, error => {
          reject(error);
        })
      })
    },

    getSettings(context, form) {
      context.state.loadingOverlay = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Api/Layanan/getSettings", form, { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)

        context.commit("getSettings", response.data.data);
        context.state.loadingOverlay = false;
      });
    },
    filterPasien_v2(context, form) {
      context.state.loading = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Api/Layanan/filterPasienV2", form, { headers })
      .then(response => {
          context.dispatch('cekResponseStatus', response.data.status)

          context.state.loading = false;
          context.commit("filterPasien", response.data.data);
        });
    },
    acceptClaim(context, form) {
      return new Promise((resolve, reject) => {
        let headers = { 
          "Authorization": context.state.token,
        };
        axios.post(api_root+"/Api/Pemeriksaan/acceptClaim", form, { headers })
        .then(response => {
          context.dispatch('cekResponseStatus', response.data.status)
          resolve(response.data);
        }, error => {
          reject(error);
        })
      })
    },
    cancelAcceptedClaim(context, form) {
      return new Promise((resolve, reject) => {
        let headers = { 
          "Authorization": context.state.token,
        };
        axios.post(api_root+"/Api/Pemeriksaan/cancelAcceptedClaim", form, { headers })
        .then(response => {
          context.dispatch('cekResponseStatus', response.data.status)
          resolve(response.data);
        }, error => {
          reject(error);
        })
      })
    },
    updateSettings(context, form){
      return new Promise((resolve, reject) => {
        let headers = { 
          "Authorization": context.state.token,
        };
        axios.post(api_root+"/Api/Layanan/updateSettings", form, { headers })
        .then(response => {
          context.dispatch('cekResponseStatus', response.data.status)
          resolve(response.data);
        }, error => {
          reject(error);
        })
      })
    },
    getPesans(context, form) {
      context.state.loadingOverlay = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Api/Pemeriksaan/getPesan", form, { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)

        context.commit("getPesans", response.data.data);
        context.state.loadingOverlay = false;
      });
    },
    storePesan(context, form) {
      return new Promise((resolve, reject) => {
        let headers = { 
          "Authorization": context.state.token,
        };
        axios.post(api_root+"/Api/Pemeriksaan/storePesan", form, { headers })
        .then(response => {
          context.dispatch('cekResponseStatus', response.data.status)
          resolve(response.data);
        }, error => {
          reject(error);
        })
      })
    },
    addPesan(context, data) {
      context.state.loadingOverlay = true;
      context.commit("addPesan", data);
      context.state.loadingOverlay = false;
    },
    updateReadedPesans(context, form) {
      return new Promise((resolve, reject) => {
        let headers = { 
          "Authorization": context.state.token,
        };
        axios.post(api_root+"/Api/Pemeriksaan/updateReadedPesans", form, { headers })
        .then(response => {
          context.dispatch('cekResponseStatus', response.data.status)
          resolve(response.data);
        }, error => {
          reject(error);
        })
      })
    },
    checkReadedPesans(context, form) {
      return new Promise((resolve, reject) => {
        let headers = { 
          "Authorization": context.state.token,
        };
        axios.post(api_root+"/Api/Pemeriksaan/checkReadedPesans", form, { headers })
        .then(response => {
          context.dispatch('cekResponseStatus', response.data.status)
          resolve(response.data);
        }, error => {
          reject(error);
        })
      })
    },
    simpanProfil(context, form) {
      context.state.loadingOverlay = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Auth/profile", form, { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)
        console.log(response);

        if (response.data.error) {
          alert(response.data.message)
        } else {
          context.commit("simpanProfil", response.data.data);
          alert(response.data.message)
          router.push("/")
        }
        context.state.loadingOverlay = false;
      });
    },
    // getMenu(context, form) {
    //   context.state.loadingOverlay = true;
    //   let headers = { 
    //     "Authorization": context.state.token,
    //   };
    //   axios.post(api_root+"/Auth/menu", form, { headers })
    //   .then(response => {
    //     context.dispatch('cekResponseStatus', response.data.status)

    //     context.commit("getMenu", response.data.data);
    //     context.state.loadingOverlay = false;
    //   });
    // },
    checkIzinAkses(context, form){
      context.state.loadingOverlay = true;
      let headers = { 
        "Authorization": context.state.token,
      };
      axios.post(api_root+"/Auth/checkIzinAksesMenu", form, { headers })
      .then(response => {
        context.dispatch('cekResponseStatus', response.data.status)

        if (response.data.error == true) {
          alert(response.data.message)
          router.push("/")
        }
        context.state.loadingOverlay = false;
      });
    },
  },
});