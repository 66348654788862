import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import Axios from 'axios'
import {
  store
} from "./store";
import IdleVue from "idle-vue"
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'

Vue.prototype.$http = Axios
Vue.config.productionTip = false

const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: (3600*1000), // 1jam
  startAtIdle: false
});
Vue.use(vueFilterPrettyBytes)

new Vue({
  router,
  vuetify,
  store: store,
  render: h => h(App)
}).$mount('#app')
