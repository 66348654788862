<template>
  <v-app>
    <ModalIdle v-if="isIdle && loggedIn" />
    <vue-internet-checker
      @status="status"
      @event="event"
    />
   
    <v-navigation-drawer v-if="loggedIn" app v-model="drawer">
      <v-list dense>
        <v-list-item link>
          <router-link :to="{name: 'Profil'}" style="">
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ user.nama_lengkap }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ user.karyawan }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </router-link>
          <v-list-item-action>
            <v-icon>mdi-account-edit</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group color="primary" v-if="user.karyawan=='RSUD'">
          <router-link
            v-for="(item, i) in menus_rsud"
            :key="i"
            :to="{ name: item.name }"
          >
            <v-list-item link>
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list-item-group>

        <v-list-item-group color="primary" v-else-if="user.karyawan=='BPJS'">
          <router-link
            v-for="(item, i) in menus_bpjs"
            :key="i"
            :to="{ name: item.name }"
          >
            <v-list-item link>
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar dense app color="indigo" dark>
      <v-app-bar-nav-icon v-if="loggedIn" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <router-link :to="{ name: 'Home' }" style="text-decoration: none; color:white;">
        <v-toolbar-title>Seva</v-toolbar-title>
      </router-link>
      <v-btn color="white" dense dark small style="position: absolute; right: 20px" v-if="loggedIn" title="Logout">
        <v-icon dense color="red darken-2" @click="logout"> mdi-logout </v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
       <v-alert
        text
        prominent
        type="error"
        icon="mdi-cloud-alert"
        style="width: 500px; z-index: 100; margin: 20px auto;"
        v-if="onLine==false"
      >
        Anda tidak tersambung Internet
      </v-alert>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<style>
  .friend_message{
    float: left;
    padding: 6px;
  }
  .my_message{
    float: right;
    padding: 6px;
    background: #00f500bd !important;
  }
</style>

<script>
import ModalIdle from "./components/ModalIdle.vue"
import vueInternetChecker from 'vue-internet-checker';
export default {
  name: "App",
  components: {
		ModalIdle
    ,vueInternetChecker
	},
  data: () => ({
    drawer: false,
    menus_rsud: [
      {
        text: "Pengaturan Klaim",
        icon: "mdi-tools",
        name: "Setting",
      },
      { text: "Verifikasi", icon: "mdi-check-box-outline", name: "Verifikasi" },
      { text: "Tentang", icon: "mdi-information", name: "About" },
    ],
    menus_bpjs:[
      { text: "Persetujuan", icon: "mdi-check-box-outline", name: "Persetujuan" },
      { text: "Tentang", icon: "mdi-information", name: "About" },
    ],
    onLine: true
  }),
  mounted() {
    // console.log(process.env.VUE_APP_API_ROOT); 
  },
  created() {
    // this.$store.dispatch("getMenu");
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    status(ele) {
      // console.log(ele);
      this.onLine = ele;
    },
    event(ele) {
      ele
      // console.log(ele);
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    user() {
      return this.$store.getters.user;
    },
    isIdle() {
			return this.$store.state.idleVue.isIdle;
		},
    overlay() {
      return this.$store.getters.loadingOverlay;
    },
  },
};
</script>
