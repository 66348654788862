<template>
  <h1>Selamat datang {{ user.nama_lengkap }}</h1>
</template>

<script>
  export default {
    name: 'Home',
    computed: {
        user() {
          return this.$store.getters.user;
        },
      },
  }
</script>
