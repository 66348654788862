<template>
  <v-dialog
    v-model="show"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Session berakhir!
        </v-card-title>
        <v-card-text>
          Anda keluar dari sistem
        </v-card-text>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Index",
  created() {
    let message= 'Session berakhir! '
    this.$store.dispatch("logout", message);
  },
  data() {
    return {
      show: false,
    }
  },
}
</script>