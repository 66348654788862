import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/auth/Login'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/profil',
        name: 'Profil',
        component: () => import(/* webpackChunkName: "Profil" */ '../views/auth/Profil.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            guest: true
        }
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/setting',
        name: 'Setting',
        component: () => import(/* webpackChunkName: "setting" */ '../views/Setting.vue'),
        meta: {
            requiresAuth: true,
            userCanAccess: ['RSUD']
        }
    },
    {
        path: '/verifikasi',
        name: 'Verifikasi',
        component: () => import(/* webpackChunkName: "Verifikasi" */ '../views/Verifikasi.vue'),
        meta: {
            requiresAuth: true,
            userCanAccess: ['RSUD']
        }
    },
    {
        path: '/persetujuan',
        name: 'Persetujuan',
        component: () => import(/* webpackChunkName: "Persetujuan" */ '../views/Persetujuan.vue'),
        meta: {
            requiresAuth: true,
            userCanAccess: ['BPJS']
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('jwt') == null) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        } else {
            let user = JSON.parse(localStorage.getItem('user'))
            if (to.matched.some(record => record.meta.userCanAccess)) {
                if (to.meta.userCanAccess.includes(user.karyawan)) {
                    next()
                }
                else {
                    next({ name: 'Home' })
                }
            } else {
                next()
            }
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (localStorage.getItem('jwt') == null) {
            next()
        }
        else {
            next({ name: 'Home' })
        }
    } else {
        next()
    }
})

export default router
